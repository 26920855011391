import React from 'react'
import PageLayout from '../components/PageLayout'
import classes from "../styles/PelenCennik.module.scss"
import { ReactComponent as CennikIcon2 } from "../static/Icons/Cennik2.svg"
import { ReactComponent as ClockIcon } from "../static/Icons/Clock.svg"
import { ReactComponent as DiscountIcon } from "../static/Icons/Discount.svg"
import { ReactComponent as WalletIcon } from "../static/Icons/Wallet.svg"

function PelenCennik() {

    const massages = [
        {
            description: "Mukabhyanga (Masaż twarzy)",
            time: "30 min",
            cost: " 150 zł",
            discount: ""
        },
        {
            description: "Mukabhyanga i Upanhasveda",
            time: "60 min",
            cost: " 190 zł",
        },
        {
            description: "Upanhasveda Padha Abhyanga - masaż pleców, rąk, stóp i łydek",
            time: "60 min",
            cost: " 170 zł",
            discount: ""
        },
        {
            description: "Upanhasveda - masaż pleców i rąk",
            time: "30 min",
            cost: "100 zł",
            discount: ""
        },
        // Additional massages
        {
            description: "Padha Abhyanga - masaż stóp i łydek",
            time: "30 min",
            cost: " 100 zł",
            discount: ""
        },
        {
            description: "Abhyanga - masaż całego ciała",
            time: "60 min",
            cost: " 190 zł",
            discount: ""
        },
        {
            description: "Abhyanga I Mukabhyanga - masaż całego ciała i twarzy",
            time: "90 min",
            cost: " 250 zł",
            discount: ""
        },
        {
            description: "Rytułał Ajurwedyjski - dowolnie wybrane partie ciała",
            time: "90 min",
            cost: " 250 zł",
            discount: ""
        },
        // Add more massages as needed...
    ];


    return (
        <PageLayout>
            <div className={classes.Cennik}>

                <div className='container is-max-widescreen mySection'>

                    <div className='mySection-TitleContainer'>
                        <div>
                            <div className='TitleIconBox'> <CennikIcon2 /> </div>
                        </div>

                        <p className='mySection-Title'> Cennik usług </p>
                    </div>

                    <div className={classes.CennikTableContainer}>

                        <div className={classes.gridContainer}>
                            <div className={classes.gridItem + " " + classes.bold}> Rodzaj masaży ajurwedyjskich </div>
                            <div className={classes.gridItem + " " + classes.bold}><ClockIcon /></div>
                            <div className={classes.gridItem + " " + classes.bold}><WalletIcon /></div>
                            <div className={classes.gridItem + " " + classes.bold}><DiscountIcon /></div>

                            {(massages && massages.length > 0) &&
                                massages
                                    .slice() // Create a copy to avoid mutating the original array
                                    .sort((a, b) => a.description.localeCompare(b.description)) // Sort by description
                                    .map((massage, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <div className={classes.gridItem} style={{ textAlign: "left" }}>{massage.description}</div>
                                                <div className={classes.gridItem} style={{ textAlign: "center" }}>{massage.time}</div>
                                                <div className={`${classes.gridItem} ${massage.discount ? classes.OldPrice : ""}`} style={{ textAlign: "center" }}>{massage.cost}</div>
                                                <div className={classes.gridItem} style={{ textAlign: "center" }}>{massage.discount}</div>
                                            </React.Fragment>
                                        );
                                    })
                            }
                        </div>

                    </div>

                </div>

            </div>
        </PageLayout>
    )
}

export default PelenCennik