import React from 'react'
import classes from "./OurMassages.module.scss"

// import { ReactComponent as AboutUsIcon } from "../../static/Icons/AboutUs.svg"
// import { ReactComponent as AboutUsIcon2 } from "../../static/Icons/AboutUs2.svg"
import { ReactComponent as ArrowRight } from "../../static/Icons/ArrowRight.svg"
import { ReactComponent as CandleIcon } from "../../static/Icons/Candle.svg"
import { useNavigate } from 'react-router-dom'

function OurMassages() {

    const navigate = useNavigate()

    return (
        <section id="nasza-oferta" className={classes.OurMassages}>
            <div className='container is-max-widescreen mySection'>


                <div className='mySection-TitleContainer'>
                    <div className='TitleIconBox'> <CandleIcon /> </div>
                    <p className='mySection-Title'> Masaże dla każdego </p>
                </div>
                <div className='mySection-DescriptionContainer'>
                    <p className='mySection-Description'>
                        Czy jesteś zapracowanym przedsiębiorcą, aktywnym sportowcem, czy też po prostu pragniesz chwili relaksu? Masaż jest dla Ciebie! Niezależnie od wieku, płci czy trybu życia.
                    </p>
                </div>


                <div className={classes.OurMassagesContainer}>

                    <div className={classes.OurMassageBox} onClick={() => navigate("/oferta#masaz_abhyanga")}>
                        <div className={classes.OurMassageBoxImageWrapper}>
                            <img src={"https://t3.ftcdn.net/jpg/01/25/59/42/360_F_125594203_KCJNBO8ifUYoahRQltpH0o25U5yP9JpJ.jpg"} alt="" />
                        </div>
                        <div className={classes.OurMassageInfoBox}>
                            <h2> Masaż całego ciała Abhyanga </h2>
                            <p> <ArrowRight /> </p>
                        </div>


                    </div>

                    <div className={classes.OurMassageBox} onClick={() => navigate("/oferta#masaz_ajurwedyjski")}>
                        <div className={classes.OurMassageBoxImageWrapper}>
                            <img src={"https://media.istockphoto.com/id/492676582/photo/spa-woman-female-enjoying-massage-in-spa-centre.jpg?s=612x612&w=0&k=20&c=qDE2CjHfmE_rm4zO13jhmx1ZsQgAP-u7vwlNGMZJtmw="} alt="" />
                        </div>
                        <div className={classes.OurMassageInfoBox}>
                            <h2> Masaże Ajurwedyjskie </h2>
                            <p> <ArrowRight /> </p>
                        </div>
                    </div>

                    <div className={classes.OurMassageBox} onClick={() => navigate("/oferta#masaze_oferta")}>
                        <div className={classes.OurMassageBoxImageWrapper}>
                            <img src={"https://www.aquasana.co.uk/treatments/massage-treatments/elemis-freestyle-deep-tissue-massage-55/_jcr_content/image.coreimg.jpeg/1648568526465/aqua-sana-mens-ultimate-back-revival.jpeg"} alt="" />
                        </div>

                        <div className={classes.OurMassageInfoBox}>
                            <h2> Oraz wiele więcej </h2>
                            <p> <ArrowRight /> </p>
                        </div>
                    </div>

                </div>


                {/* <div className={classes.OurMassagesActions}>
                    <h2 className='AboveButtonText'> Nie wiesz jaki masaż jest dla ciebie? </h2>
                    <button className='onWhiteBtn' onClick={() => { navigate("/quiz") }}> Wykonaj prosty quiz </button>
                </div> */}

            </div>
        </section>
    )
}

export default OurMassages