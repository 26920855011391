import React from 'react'
import PageLayout from '../components/PageLayout'
import Hero from '../components/Hero/Hero'
import OurMassages from '../components/OurMassages/OurMassages'
import ClientOpinions from '../components/ClientOpinions/ClientOpinions'
import Cennik from '../components/Cennik/Cennik'
import AboutUs from '../components/AboutUs/AboutUs'

function Home() {
    return (
        <PageLayout>
            <Hero />
            <AboutUs />
            <OurMassages />
            <ClientOpinions />
            <Cennik />
        </PageLayout>
    )
}

export default Home