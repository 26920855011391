import React, { useEffect, useState } from 'react'
import classes from "./PageLayout.module.scss"
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'
import { ReactComponent as ArrowUpIcon } from "../static/Icons/ArrowUp.svg"

function PageLayout({ children }) {

  const [showGoUp, setShowGoUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowGoUp(true);
      } else {
        setShowGoUp(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll behavior
    });
  };

  return (
    <div className={classes.PageLayout}>

      <div
        className={`${classes.PageGoUp} ${showGoUp ? classes.Show : classes.Hide}`}
        onClick={scrollToTop}
      >
        {/* Your ArrowUpIcon component */}
        <ArrowUpIcon />
      </div>

      <Navbar />
      <div className={classes.PageLayoutContent}>
        {children}
      </div>

      <Footer />
    </div>
  )
}

export default PageLayout