import React from 'react'
import PageLayout from '../components/PageLayout'
import classes from "../styles/PelnaOferta.module.scss"
// import classes from "../styles/PelnaOferta.module.scss"
import { ReactComponent as CennikIcon2 } from "../static/Icons/Cennik2.svg"

function Other() {
    return (
        <PageLayout>
            <div className={classes.PelnaOferta}>

                <div className='container is-max-widescreen mySection'>

                    <div className='mySection-TitleContainer'>
                        <div>
                            <div className='TitleIconBox'> <CennikIcon2 /> </div>
                        </div>

                        <p className='mySection-Title'> Nasza oferta </p>
                    </div>


                    <div className={classes.OffersWrapper}>

                        <div className={classes.OfferBox} id={`masaz_abhyanga`}>
                            <img src={"https://t3.ftcdn.net/jpg/01/25/59/42/360_F_125594203_KCJNBO8ifUYoahRQltpH0o25U5yP9JpJ.jpg"} alt="" />
                            <div className={classes.OfferBoxTextContainer}>
                                <p className={classes.OfferBoxTitle + " mySection-Title"}>
                                    Masaż całego ciała Abhyanga
                                </p>
                                <hr />
                                <p className={classes.OfferDescription}>
                                    Zanurz się w głębokiej odprężeniu i błogim relaksie podczas naszej sesji masażu całego ciała abhyanga. To niezwykłe doświadczenie zapewni Ci nie tylko chwilę błogiego luksusu, ale także przyniesie wiele korzyści zdrowotnych. Twoja skóra poczuje różnicę dzięki poprawie jej jakości, a Twoje mięśnie odprężą się, co przyniesie ulgę i redukcję bólu oraz napięcia.
                                </p>
                            </div>
                        </div>

                        <div className={`${classes.OfferBox} ${classes.OfferWrapReverse}`}>

                            <div className={classes.OfferBoxTextContainer} id={`masaz_ajurwedyjski`}>
                                <p className={classes.OfferBoxTitle + " mySection-Title"}>
                                    Masaże Ajurwedyjskie
                                </p>
                                <hr />
                                <p className={classes.OfferDescription}>
                                    Nasze Masaże Ajurwedyjskie to autentyczne doświadczenie oparte na starożytnych tradycjach ajurwedyjskich, które mają na celu przywrócenie równowagi energetycznej i odnowienie zarówno ciała, jak i ducha. Oferujemy różnorodne rodzaje masaży, aby dostosować się do Twoich indywidualnych potrzeb i preferencji.
                                </p>
                            </div>
                            <img src={"https://media.istockphoto.com/id/492676582/photo/spa-woman-female-enjoying-massage-in-spa-centre.jpg?s=612x612&w=0&k=20&c=qDE2CjHfmE_rm4zO13jhmx1ZsQgAP-u7vwlNGMZJtmw="} alt="" />

                        </div>

                        <div className={`${classes.OfferBox}`}>



                            <div className={classes.OfferBoxTextContainer} id={`masaze_oferta`}>

                                <p className={classes.OfferDescription}>
                                    <b>Masaż Twarzy</b>: Ten delikatny masaż twarzy jest doskonałym sposobem na złagodzenie napięcia mięśni twarzy, poprawę krążenia krwi i odprężenie. Poprzez odpowiednie techniki masażu, pozwalamy Ci cieszyć się promienną cerą i wewnętrznym spokojem.
                                    <br /> <br />
                                    <b>Masaż Pleców</b>: Masaż pleców to doskonały sposób na ulgę od bólu pleców i napiętych mięśni. Nasze techniki masażu pomagają rozluźnić spięte mięśnie, poprawiając mobilność i komfort. To także chwila relaksu i odprężenia dla Twojego ciała i umysłu.
                                    <br /> <br />
                                    <b>Masaż Rąk</b>: Masaż rąk nie tylko pomaga w rozluźnieniu mięśni dłoni, ale również przynosi ulgę osobom cierpiącym na ból stawów i zapewnia ogólną poprawę krążenia. To idealny zabieg dla tych, którzy potrzebują odprężenia w dłoniach i przedramionach.
                                    <br /> <br />
                                    <b>Masaż Stóp</b>: Masaż stóp to rytuał, który nie tylko relaksuje, ale także pomaga w złagodzeniu bólu stóp, redukcji obrzęków i poprawie krążenia w stopach. Po tej sesji Twoje stopy poczują się odświeżone i odprężone.
                                    <br /> <br />
                                    <b>Masaż Łydek</b>: Skupiamy się na masażu łydek, który może pomóc w złagodzeniu napiętych mięśni łydek i stawów kostkowych. Jest to również doskonały sposób na poprawę elastyczności i mobilności nóg.
                                    <br /> <br />
                                    <b>Inne Kombinacje</b>: Jeśli szukasz bardziej kompleksowego doświadczenia, możemy dostosować sesję masażu, łącząc różne techniki, aby sprostać Twoim indywidualnym potrzebom. Kombinacje masaży pozwalają na kompleksową opiekę nad ciałem i duchem.
                                </p>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </PageLayout>
    )
}

export default Other