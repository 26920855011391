import React from 'react'
import classes from "./AboutUs.module.scss"
import { ReactComponent as AboutUsIcon } from "../../static/Icons/AboutUs3.svg"
import aboutUsImg from "../../static/AboutUs.png"

function AboutUs() {
    return (
        <section id="o-nas" className={classes.AboutUs}>
            <div className={'container is-max-widescreen mySection ' + classes.AboutUsContainer}>

                <div className={classes.AboutUsWrapper}>
                    <div className='mySection-TitleContainer' style={{ alignItems: "flex-start" }}>
                        <div className='TitleIconBox gold-icon'> <AboutUsIcon /> </div>
                        <p className='mySection-Title'> Witaj, jestem Oskar </p>
                    </div>
                    <div className='mySection-DescriptionContainer'>
                        <p className='mySection-Description'>
                            Jestem terapeutą ajurwedy i specjalista od masaży, który przenosi swoich klientów w pełen relaksu świat zmysłów. Mój gabinet to oaza spokoju, gdzie każdy krok w kierunku równowagi ciała i umysłu jest prowadzony z pasją i troską.
                        </p>

                        <br />
                        <p className='mySection-Description'>

                            Podczas sesji masażu wykorzystuję starannie dobrane oleje i techniki ajurwedyjskie, które pomagają przywrócić równowagę energetyczną organizmu. Leczę ciało, ale również ducha. Delikatna muzyka w tle i aromatyczne kadzidła tworzą atmosferę, która relaksuje zmysły i pozwala pełni oddać się chwili. Klienci opuszczają gabinet z głębokim poczuciem harmonii, odnowy i wewnętrznego spokoju.

                        </p>
                    </div>
                </div>

                <div className={classes.AboutUsImage}>
                    <img src={aboutUsImg} alt="" />
                </div>




            </div>

        </section>
    )
}

export default AboutUs