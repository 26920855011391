import React from 'react'
import classes from "./Hero.module.scss"

import kwiat_bialy from "../../static/Kwiat-bialy.png"
import { useNavigate } from 'react-router-dom'

function Hero() {


    const navigate = useNavigate()



    return (
        <div id="home" className={classes.Hero}>

            <div className={classes.HeroWrapper + " container is-max-widescreen"}>
                <div className={classes.HeroTextBox}>
                    <img src={kwiat_bialy} alt="" />
                    <h1> Hej, zrelaksuj się</h1>
                    <h2> Centrum masażu leczniczego  </h2>
                    <p>Razem z nami rozpocznij podróż do świata głębokiego odprężenia, równowagi i regeneracji. Jesteśmy tu, aby Twoje ciało, umysł i dusza doświadczyły wyjątkowego momentu relaksu. </p>
                    <button className='HeroBtn' onClick={() => navigate("./kontakt")}> Umów się </button>
                </div>
            </div>

        </div>
    )
}

export default Hero