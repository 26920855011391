import React from 'react'
import classes from "../styles/Regulamin.module.scss"
import PageLayout from "../components/PageLayout"
import { ReactComponent as RegulaminIcon } from "../static/Icons/Cennik.svg"

function Regulamin() {
    return (

        <PageLayout>

            <div className={classes.Regulamin}>


                <div className='container is-max-widescreen mySection'>

                    <div className='mySection-TitleContainer'>
                        <div>
                            <div className='TitleIconBox gold-icon'> <RegulaminIcon /> </div>
                        </div>

                        <p className='mySection-Title'> Regulamin Oscar Kubiak SPA </p>
                    </div>


                    <div className={classes.RegulaminContent}>

                        <p> 
                            Przed wizytą w Oscar Kubiak SPA prosimy o zapoznanie się z naszym regulaminem. Te kilka zasad pozwoli Państwu i nam sprawić, by wizyty w SPA zawsze były przyjemnością.
                        </p>


                        <h2> I. Zasady ogólne Oscar Kubiak SPA </h2>
                        <ol>
                            <li>
                            Zabiegi umawiane są na konkretne godziny wybrane przez klienta. Spóźnienia powyżej 15 minut są podstawą do odwołania wizyty, jeżeli zabieg wpływa na dzienny harmonogram zabiegów innych klientów.

                            </li>
                            <li>
                            Oscar Kubiak SPA zastrzega sobie prawo do skrócenia czasu zabiegu o czas spóźnienia klienta
                            </li>
                            <li>
                            Podane czasy trwania zabiegów są czasami orientacyjnymi, uwzględniają czas niezbędny do przygotowania się Klienta do zabiegu jak również czas, który jest niezbędny, aby Klient opuścił gabinet po zabiegu.

                            </li>
                            <li>
                            Zakup zaproszenia upominkowego lub zapłata za zabieg jest jednoznaczna z akceptacją regulaminu Oscar Kubiak SPA
                            </li>
                            <li>
                            Na teren obiektu nie mają wstępu osoby nietrzeźwe lub wnoszące alkohol lub jakiekolwiek inne substancje odurzające. Z obiektu wyproszone mogą zostać osoby zachowujące się agresywnie lub niestosownie.
                            </li>
                            <li>
                            Pracownicy zastrzegają sobie prawo do przerwania wykonywania zabiegu, jeśli spotkają się z zachowaniem klienta nieadekwatnym do wymogów SPA. W takiej sytuacji pobierana jest pełna opłata za zabieg.
</li>
                            <li>
                            W przypadku, gdy klient nie stawi się na umówionym zabiegu, przy kolejnej rezerwacji poproszony zostanie o dokonanie przedpłaty, która przepada przy niestawieniu się na ten termin. Przedpłaty można dokonać przelewem lub gotówką. Przelew musi zostać zaksięgowany na koncie Oscar Kubiak SPA lub należy posiadać ze sobą potwierdzenie dokonania wpłaty na konto Oscar Kubiak SPA.
                            </li>

                            <li>
                            Klient zobowiązany jest do odwołania zabiegu najpóźniej 24 godziny przed umówioną wizytą.

                            </li>
                            <li>
                            Oscar Kubiak SPA nie przewiduje zwrotów wykupionych zaproszeń upominkowych ani wymiany ich na gotówkę. Prosimy o przemyślane zakupy.


                            </li>
                            <li>
                            Klient Oscar Kubiak SPA zobowiązany jest do przestrzegania terminu ważności zaproszenia upominkowego. Po upływie terminu ważności zaproszenie traci swą wartość i nie można go wykorzystać ani wymienić na środki pieniężne.


                            </li>
                            <li>
                            Oscar Kubiak SPA nie ponosi odpowiedzialności za utracone, zagubione lub zniszczone karnety i bony upominkowe.
                            </li>
                            <li>
                            Warunkiem wykonania zabiegu jest okazanie w dniu wizyty ważnego bonu upominkowego. Jeśli klient nie posiada takowego, a chce zrealizować zabieg, jest zobowiązany zapłacić gotówką, po czym donieść karnet (wtedy Oscar Kubiak SPA dokona zwrotu gotówki).

                            </li>
                            <li>
                            Klient zobowiązany jest do powiadomienia pracowników Oscar Kubiak SPA o wszelkich, znanych mu przeciwwskazaniach do wykonania zabiegu m.in.:

                                <ul>
                                    <li> Ciąży, menstruacji </li>
                                    <li> Przebytych operacjach i zabiegach chirurgicznych </li>
                                    <li> Otwartych ranach na ciele </li>
                                    <li> Problemach układu krążenia i układu oddechowego </li>
                                    <li> Zapaleniu żył, żylakach </li>
                                    <li> Stanach zapalnych dotyczących całego ciała </li>
                                    <li> Przebytych i aktywnych chorobach nowotworowych </li>
                                    <li> Innych </li>
                                </ul>
                            </li>
                            <li>
                            W przypadku wystąpienia wątpliwości pracownika Oscar Kubiak SPA, dotyczących stanu zdrowia klienta, może on odmówić wykonania zabiegu i poprosić o konsultację lekarską.

                            </li>
                            <li>
                            Dzieci do lat 14 mogą przebywać na terenie Oscar Kubiak SPA tylko pod opieką osoby dorosłej, która zadba, aby dziecko zachowywało się cicho i spokojnie.
                            </li>
                            <li>
                            Na terenie ośrodka nie wolno używać telefonów komórkowych za wyjątkiem szatni i recepcji. W przypadkach szczególnych prosimy o ściszenie telefonu.
                            </li>
                            <li>
                            Za rzeczy pozostawione w szatni Oscar Kubiak SPA nie odpowiada.

                            </li>
                        </ol>

                        <h2> II. Regulamin korzystania z Zaproszeń/voucherów w Oscar Kubiak SPA. </h2>
                        <p> Zaproszenia są bonami podarunkowymi uprawniającymi ich Posiadaczy do dokonywania płatności za usługi świadczone w Oscar Kubiak SPA.
</p>
                        <h3> 1. Zakres ogólny: </h3>
                        <p>
                            a. Kupującym Zaproszenie może być osoba fizyczna, osoba prawna lub jednostka organizacyjna nie posiadająca osobowości prawnej.

                            <br />
                            b. Kupujący dokonując zakupu Zaproszenia oświadcza, iż zapoznał się i akceptuje treść niniejszego Regulaminu.
                            <br />
                            c. Posiadacz Zaproszenia może zrealizować zaproszenie do dnia wskazanego na zaproszeniu. W tym celu Posiadacz Zaproszenia jest zobowiązany skontaktować się z Oscar Kubiak SPA telefonicznie lub za pośrednictwem e-mail najpóźniej 14 dni przed upływem daty wskazanej na zaproszeniu (termin ważności).
                            <br />
                            d. W celu zmiany terminu rezerwacji Zaproszenia na inny termin, Posiadacz zobowiązany jest skontaktować się z Oscar Kubiak SPA odpowiednio wcześniej, ale nie później niż 48h przed ustalonym terminem. Takiej zmiany można dokonać nie więcej niż 1 (jeden) raz i tylko w wyjątkowych przypadkach.

                            <br />
                            e. Niezrealizowanie Zaproszenia w terminie do dnia na nim wskazanego, oznacza rezygnację Kupującego lub Posiadacza z Realizacji Zaproszenia oraz wygaśnięcie wszelkich praw związanych z zawartą umową, w szczególności powoduje brak możliwości Realizacji Zaproszenia i z tego tytułu nie przysługują ani Posiadaczowi, ani Kupującemu żadne roszczenia, w szczególności roszczenie o zwrot zapłaconej kwoty.

                        
                            f. Poprzez funkcjonowanie naszego fanpage’a zbieramy i przetwarzany następujące rodzaje danych osobowych:
                            <br />
                            g. Oscar Kubiak SPA nie ponosi odpowiedzialności za zaproszenie, które zostało utracone lub uszkodzone przez Posiadacza.

                            <br />
                            h. Zaproszenie nie może być wymienione na ekwiwalent pieniężny.

                            <br />
                            i. Zakupione zaproszenie nie podlega zwrotowi.

                            <br />
                            j. Zaproszenie może być realizowane wyłącznie w Oscar Kubiak SPA w Ostrowie Wielkopolskim.

                            <br />
                            k. Przy realizacji zaproszenia nie przysługuje prawo otrzymania reszty, gdy wartość otrzymanej usługi jest niższa niż wartość zaproszenia.

                            <br /> 
                            l. Niestawienie się na umówiony zabieg bez wcześniejszego (24godz.) poinformowania Oscar Kubiak SPA, oznacza rezygnację Posiadacza z Realizacji Zaproszenia oraz wygaśnięcie wszelkich praw związanych z zawartą umową, w szczególności powoduje brak możliwości Realizacji Zaproszenia w innym terminie i z tego tytułu nie przysługują Posiadaczowi ani Kupującemu żadne roszczenia, w szczególności roszczenie o zwrot zapłaconej kwoty.

                            <br /><br /> 
                            Wszystkie osoby korzystające z usług SPA na podstawie zaproszenia, obowiązuje regulamin Oscar Kubiak SPA.


                        </p>
                    </div>

                </div>

            </div>

        </PageLayout>
    )
}

export default Regulamin