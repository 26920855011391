import React from 'react'
import PageLayout from '../components/PageLayout'
import classes from "../styles/Kontakt.module.scss"
import { ReactComponent as CallingIcon } from "../static/Icons/Calling.svg"
import { ReactComponent as TimeIcon } from "../static/Icons/Clock.svg"
import { ReactComponent as LocationIcon } from "../static/Icons/Location.svg"

function Kontakt() {
    return (
        <PageLayout>
            <div className={classes.Kontakt}>

                <div className='container is-max-widescreen mySection'>

                    <div className='mySection-TitleContainer'>
                        <div>
                            <div className='TitleIconBox gold-icon'> <CallingIcon /> </div>
                        </div>

                        <p className='mySection-Title'> Kontakt </p>
                    </div>


                    <div className={classes.KontaktContainer}>

                        <div className={classes.KontaktBox}>
                            <div className={classes.KontaktBoxIcon}>
                                <CallingIcon />
                            </div>

                            <h2> Kontakt z nami </h2>
                            <p> tel. 724 231 335
                                <br />
                                oscarkubiak.kontakt@gmail.com
                            </p>
                        </div>

                        <div className={classes.KontaktBox}>
                            <div className={classes.KontaktBoxIcon}>
                                <TimeIcon />
                            </div>

                            <h2> Godziny otwarcia </h2>
                            <p style={{ textAlign: "justify" }}>
                                Poniedziałek: 17:00 - 22:00 <br />
                                <span>Wtorek: Zamknięte</span> <br />
                                Środa: 17:00 - 22:00 <br />
                                Czwartek: 17:00 - 22:00 <br />
                                Piątek: 17:00 - 22:00 <br />
                                <span>Sobota: Zamknięte</span> <br />
                                Niedziela: 17:00 - 22:00 <br />

                            </p>
                        </div>

                        <div className={classes.KontaktBox}>
                            <div className={classes.KontaktBoxIcon}>
                                <LocationIcon />
                            </div>

                            <h2> Adres </h2>
                            <p> Ostrów Wielkopolski,
                                <br />
                                ul. Królowej jadwigi 2/5, 63-400
                            </p>
                        </div>

                    </div>




                </div>

                <div className='container is-max-widescreen' style={{ marginBottom: "4rem" }}>

                    <div className='mySection-TitleContainer'>


                        <p className='mySection-Title'> Zerknij na mapę </p>

                        <p> Lub </p>

                        <a href="https://maps.app.goo.gl/q8YthGyVaxWoFPgt5" target='_blank' rel="noreferrer">
                            <button className='onWhiteBtn'> Otwórz mapę w aplikacji Google </button>
                        </a>

                    </div>
                </div>

                <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1077.5235547591044!2d17.817663094067694!3d51.65069872441187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ab5b743826231%3A0x6b9b7e964a4789ab!2sKr%C3%B3lowej%20Jadwigi%202%2F5%2C%2063-410%20Ostr%C3%B3w%20Wielkopolski!5e0!3m2!1spl!2spl!4v1702667942785!5m2!1spl!2spl" width="600" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </PageLayout>
    )
}

export default Kontakt