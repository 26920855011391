import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Keep that styles order!
import "./styles/globals.scss"
import "./styles/index.scss"

// import PageLayout from './components/PageLayout';
import Home from "./pages/Home"
import PelnaOferta from './pages/PelnaOferta';
import PelenCennik from './pages/PelenCennik';
import Kontakt from './pages/Kontakt';
// import Quiz from './components/Quiz/Quiz';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Faq from './pages/Faq';
import Regulamin from './pages/Regulamin';
import Voucher from './pages/Voucher';


// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/oferta" element={<PelnaOferta />} />
        <Route path="/cennik" element={<PelenCennik />} />
        <Route path="/kontakt" element={<Kontakt />} />
        {/* <Route path="/quiz" element={<Quiz />} /> */}
        <Route path="/faq" element={<Faq />} />
        <Route path="/regulamin" element={<Regulamin />} />
        <Route path="/voucher" element={<Voucher />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
