import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PageLayout from '../components/PageLayout'
import classes from "../styles/Faq.module.scss"
import { ReactComponent as QuestionIcon } from "../static/Icons/question.svg"
import { ReactComponent as ArrowDown } from "../static/Icons/ArrowDownShort.svg"

function QuestionContainer({ children }) {

    const question = children[0]
    const answer = children[1]

    const [isAnswerHidden, setIsAnswerHidden] = useState(true)

    return (
        <div className={classes.QuestionContainer}>
            <div className={classes.QuestionBox}>
                {question}
                <div className={classes.QuestionArrowBox} onClick={() => { setIsAnswerHidden(!isAnswerHidden) }}>
                    <ArrowDown className={`${(!isAnswerHidden) && classes.rotateSVG}`} />
                </div>
            </div>

            <div className={`${classes.AnswerBox} ${(isAnswerHidden) && classes.AnswerBoxHidden}`}>
                {answer}
            </div>

        </div>
    )
}

function Faq() {
    const navigate = useNavigate()

    const questions = {
        1 : "Czy mogę podać swoje preferencje co do intensywności masażu?",
        2 : "Czy masaż może być bolesny?",
        3 : "Czy mogę przynieść własne oleje do masażu?",
        4 : "Jakie są korzyści zdrowotne masażu?",
        5 : "Czy masaż jest bezpieczny dla mojego zdrowia?",
        6 : "Czy muszę się całkowicie rozebrać?"
    }

    const answers = {
        1: "Oczywiście! Jesteśmy tu po to, aby dostosować doświadczenie masażu do Twoich indywidualnych potrzeb. Twoje preferencje co do intensywności masażu są bardzo ważne, więc śmiało wyrażaj swoje oczekiwania. Chcemy, abyś poczuł się komfortowo i zrelaksowany podczas całej sesji. Twoje zadowolenie jest naszym priorytetem!",
        2: "Masaż zazwyczaj nie powinien być bolesny, ale istnieje możliwość odczucia pewnego dyskomfortu w miejscach spięć czy napięć mięśniowych. Naszym celem jest zapewnienie Ci relaksu i ulgi, jednak w trakcie sesji może się zdarzyć, że terapeuta zwraca uwagę na konkretne obszary, gdzie mogą występować pewne napięcia. W przypadku odczuwania zbyt dużego dyskomfortu, zawsze zachęcamy do bezpośredniego komunikowania się z nami, abyśmy mogli dostosować intensywność masażu do Twoich preferencji i wygodnie poprowadzić sesję. Twój komfort oraz zdrowie są dla nas priorytetem.",
        3: "Oczywiście! Jesteśmy otwarci na spełnianie Twoich indywidualnych potrzeb. Jeśli preferujesz korzystanie z własnych olejów do masażu, z przyjemnością dostosujemy się do Twoich życzeń. Twój komfort i satysfakcja są dla nas ważne, dlatego chętnie uwzględnimy Twoje preferencje podczas sesji masażu.",
        4: "Masaż oferuje liczne korzyści zdrowotne! To nie tylko relaks dla ciała, ale także dla umysłu. Korzyści obejmują redukcję stresu, złagodzenie napięcia mięśniowego, poprawę krążenia krwi, wspomaganie procesów detoksykacji, zwiększenie elastyczności mięśni, poprawę snu oraz ogólną poprawę samopoczucia. Każda sesja masażu jest indywidualnie dostosowana, aby dostarczyć Ci kompleksowe doświadczenie odprężenia i wspierania zdrowia",
        5: "Tak, masaż jest zazwyczaj bezpieczny dla zdrowia. Przed sesją warto jednak poinformować terapeutę o ewentualnych schorzeniach czy dolegliwościach zdrowotnych, aby mógł dostosować sesję do Twoich potrzeb. Profesjonalny masażysta podejmie wszelkie środki ostrożności, aby zapewnić Ci bezpieczne i skuteczne doświadczenie masażu. Jeśli masz konkretne obawy dotyczące swojego zdrowia, zawsze warto przedyskutować je z terapeutą przed rozpoczęciem sesji.",
        6: "Nie ma konieczności całkowitego rozbierania się podczas sesji masażu. Ważne jest, abyś czuł się komfortowo. Zazwyczaj masażysta dostosuje procedurę do Twoich preferencji i zaznaczy, które części ubrania można pozostawić. Terapeutyczne odkrywanie tylko konkretnych obszarów ciała umożliwia skoncentrowanie się na relaksacji i zminimalizowanie dyskomfortu. W każdym przypadku ważne jest, abyś czuł się swobodnie i bezpiecznie, więc możesz rozmawiać z masażystą o swoich preferencjach dotyczących ubioru przed rozpoczęciem sesji.",
    }

    // Remember to have answers and question with same keys and equal amount of keys! 

    return (
        <PageLayout>
            <div className={classes.Faq}>
                <div className='container is-max-widescreen mySection'>

                    <div className='mySection-TitleContainer'>
                        <div>
                            <div className='TitleIconBox gold-icon'> <QuestionIcon /> </div>
                        </div>

                        <p className='mySection-Title'> Sekcja pytań i odpowiedzi  </p>

                        <div className='mySection-DescriptionContainer' style={{ textAlign: "center" }}>
                            <p className='mySection-Description'>
                                Tutaj znajdziesz najczęściej zadawane pytania oraz ich odpowiedzi.
                            </p>
                        </div>

                    </div>

                    <div className={classes.QuestionsWrapper}>

                        {Object.keys(questions).map((key, idx) => {
                            return (
                                <QuestionContainer key={`question-${idx}`}>
                                    <p> {questions[key]} </p> 
                                    <p> {answers[key]} </p>
                                </QuestionContainer>
                            )
                        })}

                        <p className='mySection-Description' style={{ marginTop: "4rem" }}>
                            Jeżeli masz pytanie które nie pojawiło się na powyższej liście.
                        </p>


                        <button className='onWhiteBtn' onClick={() => { navigate("/kontakt") }}> Skontaktuj się z nami </button>

                    </div>
                </div>

            </div>

        </PageLayout>
    )
}

export default Faq