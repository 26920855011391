import React from 'react'
import classes from "./PageNotFound.module.scss"
import PageLayout from "../PageLayout"
import { ReactComponent as ErrorIcon } from "../../static/Icons/Danger.svg"
import { useNavigate } from 'react-router-dom'


function PageNotFound() {
    const navigate = useNavigate()

    return (
        <PageLayout>
            <div className={classes.PageNotFound}>
                <div className='container is-max-widescreen mySection'>

                    <div className='mySection-TitleContainer'>
                        <div>
                            <div className='TitleIconBox'> <ErrorIcon /> </div>
                        </div>

                        <p className='mySection-Title'> Upss, coś poszło nie tak. </p>

                        <h1 className='error_404'> 404 </h1>
                        <div className='mySection-DescriptionContainer' style={{ textAlign: "center" }}>
                            <p className='mySection-Description'>
                                Trafiłeś na stronę która nie istnieje.
                            </p>
                        </div>

                        <button className='onWhiteBtn' onClick={() => { navigate("/") }}> Wróć na stronę główną </button>
                    </div>
                </div>

            </div>

        </PageLayout>
    )
}

export default PageNotFound