import React from "react";
import PageLayout from "../components/PageLayout";
import classes from "../styles/Voucher.module.scss";
import { Link } from "react-router-dom";
import logo_primary from "../static/Logo-Primary-Crop.png"

function Voucher() {
  return (
    <PageLayout>
      <div className={classes.Voucher}>
        <div className="container is-max-widescreen mySection">
          <div className="mySection-TitleContainer">
            <p className="mySection-Title"> Zakup Voucher </p>
          </div>


          <div className={classes.VoucherCard}>
            <img src={logo_primary} alt="Logo" />

          </div>


<div className={classes.VoucherContent}>

<p className="mySection-Description">
              W Celu wykupienia Vouchera, prosimy wykonać przelew na poniższy
              numer bankowy:
            </p>

          <p  style={{margin: "1rem 0", fontWeight: "500", fontSize: "1.2rem"}}> 02 1020 2267 0000 4702 0160 0337 </p>

          <p> Lub <br/>Numer telefonu: </p>

          <p  style={{margin: "1rem 0", fontWeight: "500", fontSize: "1.2rem"}}> +48 724 231 335 </p>

          <p>
W tytule przelewu prosimy o podanie swojego <b>adresu e-mail</b> oraz <b>imienia</b> i <b>nazwiska</b> osoby, dla której ma być ważny voucher.
          </p>

          <p>
            Po wykonaniu przelewu odeślemy Panstwu wiadomość zwrotną z
            potwierdzeniem zakupu Vouchera.
          </p>

          <p>
            Przed wykonaniem przelewu, prosimy zapoznać się z naszym <Link to="/regulamin">Regulaminem</Link>
          </p>


          <p style={{marginTop: "2rem"}}>
            W razie jakichkolwiek pytań, zaprszamy do kontaktu z nami
          </p>
          <Link to="/kontakt"><button className="onWhiteBtn"> Skontaktuj się </button></Link>

          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Voucher;
