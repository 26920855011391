import React from 'react'
import classes from "./Footer.module.scss"

import logo_black from "../../static/Kwiat-czarny.png"
import { useNavigate } from 'react-router-dom'

function Footer() {
    const navigate = useNavigate()

    return (
        <div className={classes.Footer}>

            <div className={classes.FooterContainer + " container is-max-widescreen mySection"}>

                <div className={classes.RowForLogo}>
                    <img className={classes.FooterLogo} src={logo_black} alt="Logo" />
                    <p>
                        Daj ulgę swojemu ciału już dziś
                    </p>
                </div>


                <div className={classes.FooterWrapper}>
                    <div className={classes.FooterCol}>
                        <div className={classes.FooterListContainer}>
                            <ul>
                                <li> Strony </li>
                                <li onClick={() => window.location.href = "/"}> Home </li>
                                <li onClick={() => navigate('/#o-nas')}> O Nas </li>
                                <li onClick={() => navigate('/oferta')}> Oferta </li>
                                <li onClick={() => navigate('/#cennik')}> Cennik </li>
                                {/* <li> Umów się na wizytę </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className={classes.FooterCol}>
                        <div className={classes.FooterListContainer}>
                            <ul>
                                <li> Przydatne linki  </li>
                                <li onClick={() => navigate('/faq')}> FAQ </li>
                                {/* <li> Blog </li> */}
                                <li onClick={() => navigate('/kontakt')}>Kontakt </li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.FooterCol}>
                        <div className={classes.ContactUs}>
                            <h2> Skontaktuj się z nami </h2>
                            <p> Odpowiadamy w trybie ekspresowym! </p>

                            <a href="mailto:oscarkubiak.kontakt@gmail.com?subject=Zapytanie ze strony internetowej"><button className="onWhiteBtn" type="submit"> Wyślij wiadomość </button></a>

                            {/* <form>
                                <label htmlFor='email'> Podaj swój email </label>
                                <input class="input" id="email" type="text" placeholder='Email' />

                                <label htmlFor='text'> Treść maila </label>
                                <textarea class="input" id="text" rows="4" cols="50" placeholder='Treść maila'>

                                </textarea>

                                <button class="onWhiteBtn" type="submit"> Wyślij wiadomość </button>

                            </form> */}
                        </div>

                    </div>
                </div>

                <div className={classes.FooterCopyRights}>
                    <p> &copy; 2023+ Oskar Kubiak. Wszelkie prawa zastrzeżone. </p>

                    <div className={classes.FooterCopyRightsButtons}>
                        <p onClick={() => navigate('/regulamin')}> Regulamin </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Footer