import React, { useEffect, useState } from 'react'
import classes from "./Navbar.module.scss"
import { useLocation, useNavigate } from 'react-router-dom';

// import logo_black from "../../static/Logo-Czarne.png"
// import logo_black from "../../static/Logo-Czarne-Crop.png"
import logo_primary from "../../static/Logo-Primary-Crop.png"
// import kwiat_black from "../../static/Kwiat-czarny.png"

import { ReactComponent as FacebookIcon } from "../../static/Icons/Facebook.svg"
import { ReactComponent as InstagramIcon } from "../../static/Icons/Instagram.svg"
import { ReactComponent as CallingIcon } from "../../static/Icons/Calling.svg"
import { ReactComponent as MessageIcon } from "../../static/Icons/Message.svg"
// import { ReactComponent as ArrowRight } from "../../static/Icons/ArrowRight.svg"

function Navbar() {
    const [navbarToggle, setNavbarToggle] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // Smooth scroll do lokalizacji w linku 
    // Przykładowo: localhost/#o-nas <- Scroll do id "o-nas"
    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const element = document.getElementById(hash.substring(1)); // Remove '#' from hash

            if (element) {
                const offset = element.offsetTop - 100; // Adjusted offset (100px before the section)
                window.scrollTo({
                    top: offset,
                    behavior: 'smooth'
                });
            }
        }

        else {
            // No hash, scroll to the top of the page
            window.scrollTo({
                top: 0,
                behavior: 'auto' // Normal scroll
            });
        }

    }, [location]);

    function toggleNavbar() {
        setNavbarToggle(!navbarToggle);
    }

    function handleNavigation(path) {
        navigate(path);
    }

    function openNewPage(linkTo) {
        window.open(linkTo, "_blank");
    }


    return (

        <nav className={classes.myNavbarContainer} role="navigation" aria-label="main navigation">
            <div className={classes.myNavbarUpperContainerWrapper}>
                <div className={'container is-max-widescreen ' + classes.myNavbarUpperContainer}>

                    <div className={classes.myNavbarUpperBox} onClick={() => { openNewPage("https://www.facebook.com/profile.php?id=61554005463671") }}>
                        <FacebookIcon />
                    </div>
                    <div onClick={() => { openNewPage("https://www.instagram.com/oscar_kubiak/?igshid=MmVlMjlkMTBhMg%3D%3D") }} className={classes.myNavbarUpperBox}>
                        <InstagramIcon />
                    </div>
                    <div className={classes.myNavbarUpperBox}>
                        <a href="tel:+48724231335"><p className={classes.myNavbarUpperText}> <CallingIcon /> +48 724 231 335</p></a>
                    </div>
                    <div className={classes.myNavbarUpperBox}>
                    <a href="mailto:oscarkubiak.kontakt@gmail.com"><p className={classes.myNavbarUpperText}> <MessageIcon /> oscarkubiak.kontakt@gmail.com </p></a>
                    </div>

                </div>


            </div>

            <div className={"navbar " + classes.myNavbar}>
                <div className="container is-max-widescreen">

                    <div className={"navbar-brand " + classes.myNavbarBrand}>

                        {/* Logo  */}
                        <p className={classes.myNavbarBrandContainer} onClick={() => { window.location.href = "/" }}>
                            <img alt="Logo" src={logo_primary} />
                        </p>

                        <p onClick={toggleNavbar} role="button" className={"navbar-burger " + classes.myNavbarBurger + " " + ((navbarToggle) ? "is-active" : "")} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </p>
                    </div>

                    <div id="navbarBasicExample" className={"navbar-menu " + classes.myNavbarMenu + " " + ((navbarToggle) ? "is-active" : "")}>
                        <div className="navbar-start">

                        </div>

                        <div className="navbar-end">
                            <p className={"navbar-item " + classes.myNavbarItem} onClick={() => { handleNavigation("/#home") }}>
                                Home
                            </p>

                            <p className={"navbar-item " + classes.myNavbarItem} onClick={() => { handleNavigation("/#o-nas") }}>
                                O nas
                            </p>

                            <p className={"navbar-item " + classes.myNavbarItem} onClick={() => { handleNavigation("/oferta") }}>
                                Oferta
                            </p>

                            <p className={"navbar-item " + classes.myNavbarItem} onClick={() => { handleNavigation("/#cennik") }}>
                                Cennik
                            </p>

                            <p className={"navbar-item " + classes.myNavbarItem} onClick={() => { handleNavigation("/kontakt") }}>
                                Kontakt
                            </p>

                            <p className={"navbar-item " + classes.myNavbarItem} onClick={() => { handleNavigation("/voucher") }}>
                                Voucher
                            </p>

                            {/* <div className="navbar-item">
                                <div className="buttons">
                                    <p className={"myNavButton"}>
                                        Umów się na wizytę <ArrowRight />
                                    </p>

                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </nav>
    )
}

export default Navbar