import React from 'react'
import classes from "./Cennik.module.scss"
import { ReactComponent as CennikIcon2 } from "../../static/Icons/Cennik2.svg"

function Cennik() {
    return (
        <div id="cennik" className={classes.Prices}>

            <div className='container is-max-widescreen mySection'>

                <div className='mySection-TitleContainer'>
                    <div>
                        <div className='TitleIconBox'> <CennikIcon2 /> </div>
                    </div>

                    <p className='mySection-Title'> Cennik usług </p>
                </div>

                <div className={classes.PricesContainer}>

                    <div className={classes.PriceBox}>

                        <h2> Masaż całego ciała <br /> <b>abhyanga</b> </h2>
                        <hr />
                        <p> od <b>190</b> zł </p>
                        <ul>
                            <li> - Czas trwania: 1h </li>
                            <li> - Relaksacja i Odprężenie </li>
                            <li> - Odtruwanie Organizmu </li>
                            <li> - Stymulacja Punktów Marmy </li>
                            <li> - Redukcja Bólu i Napięcia Mięśniowego </li>
                            <li> - Stworzenie Harmonii Ciała i Umysłu </li>
                        </ul>
                    </div>

                    <div className={classes.PriceBox}>

                        <h2> Masaże <br /> <b>ajurwedyjskie</b> </h2>
                        <hr />
                        <p> od <b>100</b> zł </p>
                        <ul>
                            <li> - Czas trwania: od 30 do 90 minut </li>
                            <li> - Redukcja Napięcia Mięśniowego </li>
                            <li> - Stymulacja Układu Limfatycznego </li>
                            <li> - Poprawa Postawy </li>
                            <li> - Lepszy Sen </li>
                            <li> - Poprawa Jakości Życia </li>
                            <li> - Stymulacja Punktów Refleksyjnych </li>
                            <li style={{ color: "#999" }}> i wiele więcej.. </li>
                        </ul>
                    </div>
                </div>

                <div className={classes.PricesActions}>
                    <h2 className='AboveButtonText'> Sprawdź nasz pełen zakres usług oraz cen tutaj </h2>
                    <a href="/cennik"><button className='onWhiteBtn'> Pełen cennik </button></a>
                </div>
            </div>
        </div >
    )
}

export default Cennik