import React from 'react'
import classes from "./ClientOpinions.module.scss"
import { ReactComponent as StarIcon } from "../../static/Icons/Star.svg"
import { ReactComponent as StarIcon2 } from "../../static/Icons/Star2.svg"

function ClientOpinions() {
    return (
        <div className={classes.ClientOpinions}>

            <div className='container is-max-widescreen mySection'>

                <div className='mySection-TitleContainer mySection-center'>
                    <div>
                        <StarIcon2 />
                        <StarIcon2 />
                        <StarIcon2 />
                        <StarIcon2 />
                        <div className='TitleIconBox gold-icon' > <StarIcon2 /> </div>
                    </div>

                    <p className='mySection-Title'> Jakoś doceniona przez klientów </p>
                </div>
                <div className='mySection-DescriptionContainer' style={{ textAlign: "center" }}>
                    <p className='mySection-Description'>
                        Najlepszym potwierdzeniem jakości i bezpieczeństwa usług są opinie naszych Klientów. Dziękujemy za zaufanie.
                    </p>
                </div>


                <div className={classes.OpinionsContainer}>
                    <div className={classes.OpinionBox}>
                        <div className={classes.OpinionBoxWrapper}>
                            <p className={classes.Stars}> <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></p>
                            <p className={classes.OpinionText}>
                                Doświadczenie z masażem u Oskara było niesamowite! Jego umiejętności ajurwedyjskiej terapii są nie do pobicia. Po sesji czułam się jak nowo narodzona, pełna spokoju i energii.
                            </p>
                            <p className={classes.OpinionClient}>~Marta, Ostrów Wlkp.</p>
                        </div>
                    </div>

                    <div className={classes.OpinionBox}>
                        <div className={classes.OpinionBoxWrapper}>
                            <p className={classes.Stars}> <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></p>
                            <p className={classes.OpinionText}>
                                Oskar to prawdziwy mistrz! Jego gabinet to raj dla zmysłów. Po masażu czułem się, jakbym opuścił stresujący świat i znalazł się w oazie spokoju. To nie tylko masaż, to podróż do pełnej harmonii.
                            </p>
                            <p className={classes.OpinionClient}> ~Tomasz, Krotoszyn </p>
                        </div>
                    </div>

                    <div className={classes.OpinionBox + " " + classes.OpinionBoxMiddle}>
                        <div className={classes.OpinionBoxWrapper}>
                            <p className={classes.Stars}> <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></p>
                            <p className={classes.OpinionText}>
                                Masaż u Oskara to niezapomniane doświadczenie. Jego indywidualne podejście sprawia, że czujesz się ważny i zrozumiany. Po sesji nie tylko znikają bóle i napięcia, ale również zanika stres, pozostawiając uczucie lekkości i głębokiego relaksu. Gorąco polecam!
                            </p>
                            <p className={classes.OpinionClient}>~Ania, Ostrów Wlkp.</p>
                        </div>
                    </div>
                </div>




            </div>
        </div >
    )
}

export default ClientOpinions